<template>
  <b-card>
    <change-contact 
      :is-from-settings="true" 
      :item="currentCollective" 
      :type="getType" 
    />
  </b-card>
</template>

<script>
import ChangeContact from '@/@core/components/contact/ChangeContact.vue';

export default {
  name: 'Contact',
  components: {
    ChangeContact,
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    getType() {
      console.log('this.currentCollective', this.currentCollective);
      return this.currentCollective?.mainType?.toLowerCase();
    }
  },
  methods: {
  }
};
</script>

<style>

</style>
